/*
This is where you write custom SASS
*/

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

section.ctas {
    .mod-wrap {
        position: relative;
        width: 390px;
        height: 204px;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 auto;
    
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: 390px 100%;
        }
        .background-img {
            background-image: url('/prositesStyles/StyleLibrary/6707/images/cta3b.jpg');
        }
        .foreground-img {
            background-image: url('/prositesStyles/StyleLibrary/6707/images/cta3a.jpg');
            width: 50%;
        }
        .slider {
            position: absolute;
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            height: 100%;
            background: rgba(#f2f2f2, 0);
            outline: none;
            margin: 0;
            transition: all 0.2s;
            @include center;

            // &:hover {
            //     background: rgba(#f2f2f2, 0.1);
            // }
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 6px;
                height: 204px;
                background: white;
                cursor: pointer;
            }
            &::-moz-range-thumb {
                width: 6px;
                height: 204px;
                background: white;
                cursor: pointer;
            }
        }
        .slider-button {
            $size: 30px;
            pointer-events: none;
            position: absolute;
            width: $size;
            height: $size;
            border-radius: 50%;
            background-color: white;
            left: calc(50% - 17px);
            top: calc(50% - 18px);
            @include center;
    
            @mixin arrow-helper() {
                content: "";
                padding: 3px;
                display: inline-block;
                border: solid #5d5d5d;
                border-width: 0 2px 2px 0;
            }
            &:after {
                @include arrow-helper();
                transform: rotate(-45deg);
            }
            &:before {
                @include arrow-helper();
                transform: rotate(135deg);
            }
        }
    }
}

// section.hero-section .wrap-video {
//     position: relative;
//     width: 100%;
//     height: 0;
//     padding-bottom: 56.25%;
// }
// section.hero-section .video {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translate3d(-50%, 0, 0);
//     width: 100%;
//     height: 100%;
// }
// @media (max-width: 1800px) {
//     section.hero-section .video {
//         transform: translate3d(-65%, 0, 0);
//     }
//     section.hero-section .wrap-video {
//         width: 143%;
//         padding-bottom: 60.25%;
//     }
// }
// @media (max-width: 1600px) {
//     section.hero-section .video {
//         transform: translate3d(-65%, 0, 0);
//     }
//     section.hero-section .wrap-video {
//         width: 120%;
//         padding-bottom: 67.25%;
//     }
// }
// @media (max-width: 1400px) {
//     section.hero-section .wrap-video {
//         width: 140%;
//         padding-bottom: 78.25%;
//     }
//     section.hero-section .video {
//         transform: translate3d(-65%, 0, 0);
//     }
// }
// @media (max-width: 1200px) {
//     section.hero-section .wrap-video {
//         width: 155%;
//         padding-bottom: 82.25%;
//     }
//     section.hero-section .video {
//         transform: translate3d(-66%, 0, 0);
//     }
// }
// @media (max-width: 992px) {
//     section.hero-section .logo {
//         width: 450px;
//     }
//     section.hero-section .wrap-video {
//         padding-bottom: 87.25%;
//     }
//     section.hero-section .video {
//         transform: translate3d(-68%, 0, 0);
//     }
// }
// @media (max-width: 768px) {
//     section.hero-section .wrap-video {
//         width: 210%;
//         padding-bottom: 117.25%;
//     }
//     section.hero-section .video {
//         transform: translate3d(-75%, 0, 0);
//     }
// }
// @media (max-width: 576px) {
//     section.hero-section, section.hero-section .mod, section.hero-section .wrap {
//         height: 400px !important;
//     }
//     section.hero-section .logo {
//         width: 250px;
//     }
//     section.hero-section .video {
//         transform: translate3d(-79%, 0, 0);
//     }
//     section.hero-section .wrap-video {
//         width: 242%;
//         padding-bottom: 105.25%;
//     }

// }
// @media (max-width: 480px) {
//     section.hero-section .video {
//         transform: translate3d(-82%, 0, 0);
//     }
//     section.hero-section .wrap-video {
//         width: 280%;
//         padding-bottom: 107.25%;
//     }
// }
// @media (max-width: 375px) {
//     section.hero-section .video {
//         transform: translate3d(-84%, 0, 0);
//     }
//     section.hero-section .wrap-video {
//         width: 315%;
//         padding-bottom: 125.25%;
//     }
// }