section.hero-section {
    min-height: 400px;
    height: 927px;

    .mod, .wrap {
        min-height: 400px;
        height: 927px;

        .top {
            letter-spacing: 0.02em;
            font-size: 28px;
    
            .brdr {
              border-right: 1px solid rgba(255,255,255,1);
              font-size: 21px;
            }
            br {
              display: none;
            }
            .social {
                font-size: 21px;
            }
        }
    }
}