
section.ctas {
    .mw {
        max-width: 1350px;
        margin: 0 auto;
    }
    .mod {
        .overlay1 {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000000;
            opacity: 0.2;
            overflow: hidden;
            width: 0;
            height: 100%;
            transition: .4s ease;
        }
        .d-flex {
            border-radius: 20px;
            overflow: hidden;
            width: 390px;
            margin: 0 auto;
        }
        .h4 {
            font-size: 19px;
            color: #2B2B2B;
            font-weight: 700;
            letter-spacing: 0.05em;
            transition: .3s;
            position: absolute;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }
        .h4::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: -10px;
            left: 0;
            background-color: #ED8B00;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }
        img {
            transition: .3s ease;
        }
        .cta-hvr {
            transition: 0.3s ease !important;
        }
        a:hover {
            box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);

            .h4 {
                letter-spacing: 0.15em;
            }
            .h4::before {
                transform: scaleX(1);
            }
            .cta-hvr {
                box-shadow: 0px 13px 40px rgba(0, 0, 0, 0.2);
                transform: translate3d(0, -2px, 0);
            }
            img {
                transform: scale(1.05);
            }
            .cta-hvr .overlay1 {
                width: 100%;
              }
        }
    }
}

section.main {
    p:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
        letter-spacing: 0.02em;
        line-height: 32px;
    }
}

section.map {
    margin: 0 0 -10px 0;
}

section.associations {
    text-align: center;
}

footer.foot {
    background-color: #17191A;

    br {
        display: none;
    }
    .info {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;

        .brdr {
            border-right: 1px solid rgba(255,255,255,.2);
            font-size: 26px;
            height: 26px;
        }
        br {
            display: none;
        }
        .social {
            font-size: 20px;
        }
    }
    .btm {
        color: #676B72;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }
}

.btn {
    border-radius: 20px;
}

.btn-primary:hover {
    color: #fff;
    border-color: $secondary !important;
    background-color: $secondary !important;
}

.bg-black1 {
    background-color: #17191A;
}

.bg-nav {
    background-color: transparent;
    transition: ease-out .3s;
}

.scrolling .bg-nav {
    background-color: #17191A;
}

#ADAButton {
    line-height: 0 !important;
}


.scroll-up {
    &:hover {
        box-shadow: none !important;
    }
}

.text-initial {
    text-transform: initial;
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 650px;
        min-height: 300px;

        .h-mod {
            height: 650px;

            .wrap {
                .logo {
                    max-width: 594px;
                    margin: 0 auto;
                    z-index: 1;
                }
            }
        }
    }

    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: #17191A;
            color: #fff;
            border-radius: 4px;
            p.h3 {
                font-size: 26px;
                font-weight: 400;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.02em;
                margin-bottom: 20px !important;
            }
            label {
                display: none;
            }
            ::placeholder {
                color: #26282B;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 0.02em;
            }
            .form-control {
                color: #26282B;
                font-size: 14px;
                font-weight: 400;
                text-transform: none;
                letter-spacing: 0.02em;
                border: none;
            }
            textarea {
                min-height: 142px;
            }
            .text-muted {
                color: #ffffff !important;
            }
        }
    }
    &.page-type-40 {
        .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
            background: #26282B !important;
        }
        .owl-theme .owl-dots .owl-dot span {
            background: rgba($color: #26282B, $alpha: 0.25) !important;
        }
    }
}

#to-top{
    display:block;
    position:fixed;
    text-align:center;
    line-height:12px;
    right:17px;
    bottom:-30px;
    color:#fff;
    cursor:pointer;
    border-radius: 50px;
    z-index:9994;
    height:29px;
    width:29px;
    background-color:rgba(0,0,0,0.25);
    background-repeat:no-repeat;
    background-position:center;
    transition:background-color 0.1s linear;
    -webkit-transition:background-color 0.1s linear;
    &:hover {
        color:#ffffff;
        background-color: $secondary;
    }
    &:after {
        color:#ffffff;
        background-color: $secondary !important;
    }
}

#to-top{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  background-color:rgba(0,0,0,0.25);
}

#to-top:hover, 
#to-top.dark:hover{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  box-shadow:1px 2px 3px rgba(0,0,0,0.16);
  background-color:transparent!important;
}
#to-top:after, 
#to-top:before{
    display:block;
    content:' ';
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color:#000;
    transform:scale(0);
    -webkit-transform:scale(0);
    transition:all 0.3s cubic-bezier(.55,0,.1,1);
    -webkit-transition:all 0.3s cubic-bezier(.55,0,.1,1);
    border-radius: 50px;
    -webkit-border-radius: 50px;
}
#to-top:before{
  background-color:rgba(255,255,255,0.25);
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  -webkit-transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  opacity:0;
  z-index:2;
}

#to-top:hover:after{
  transform:scale(1);
  -webkit-transform:scale(1);
}
#to-top{
  overflow:hidden;
}

#to-top i.fa-angle-up.top-icon, 
#to-top i.fa-angle-up{
  -webkit-transform:translate(0,0px);
  transform:translate(0,0px);
  transition:transform 0.2s ease;
  -webkit-transition:transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon, 
#to-top:hover i.fa-angle-up, 
#to-top.hovered i.fa-angle-up.top-icon, 
#to-top.hovered i.fa-angle-up{
  -webkit-transform:translate(0,-29px);
  transform:translate(0,-29px);
}

#to-top:active:before{
  opacity:1;
}

#to-top i{
  line-height:29px;
  width: 29px;
  height: 29px;
  font-size:14px;
  top:0;
  left:0;
  text-align:center;
  position:relative;
  z-index:10;
  background-color:transparent; 
}
#to-top:hover, 
#to-top.dark:hover{
  background-color:#000;
}
#to-top.dark{
  background-color:rgba(0,0,0,0.87);
}

[class*="btn"]:not(.no-fx), .hover-fx {
    transition: 0.3s ease !important;
}
[class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

// Apointment Request Form

.list-group.mb-5 {
    >.list-group.mb-5 {
        >a:first-child {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(2),
        +a {
            border-top-right-radius: $border-radius !important;
            border-top-left-radius: $border-radius !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(3) {
            border-bottom-right-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
            margin-bottom: 3rem;
        }
    }

    .wysiwyg>& {
        >a.list-group-item.list-group-item-action>.text-muted {
            font-weight: bold !important;
            color: $list-group-action-color !important;
        }
    }
}

.modal-open {
    overflow: auto;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.extra-small {
    font-size: $extra-small-font-size !important;
    line-height: $small-line-height !important;
}

.navbar-brand {
    font-family: $navbar-brand-font-family !important;
    white-space: normal;
}

blockquote {
    font-family: $blockquote-font-family;
    font-size: $blockquote-font-size;
    font-style: $blockquote-font-style;
    line-height: $blockquote-line-height;
    letter-spacing: $blockquote-letter-spacing;
    font-weight: $blockquote-font-weight;

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        font-size: $font-size-lg !important;
        line-height: $lead-line-height !important;
    }
}

html {
    margin: 0 !important;
    overflow-x: hidden;
}

hr {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto !important;
}

label {
    font-size: $label-font-size;
    line-height: $label-line-height;
    letter-spacing: $label-letter-spacing;
    font-weight: $label-font-weight;
    text-transform: $label-text-transform;
}

body {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;
    overflow-x: hidden;
    line-height: 1.6em;
    transition: opacity 0.85s ease 0.3s;
}

a {
    transition: 0.4s ease;
    text-decoration: none !important;
}

[data-brand*="fa-yelp"] {
    background-color: #af0606;
}

[data-brand*="fa-instagram"]:not([class*="bg-"]) {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

[data-brand*="fa-facebook"] {
    background-color: #3b5998;
}

[data-brand*="fa-twitter"] {
    background-color: #1da1f2;
}

[data-brand*="fa-youtube"] {
    background-color: #ff0000;
}

[data-brand*="fa-linkedin"] {
    background-color: #0077b5;
}

[data-brand*="fa-google"] {
    background-color: #4285f4;
}

[data-brand*="fa-google-plus"] {
    background-color: #dd4b39;
}

.social-media>a:not([href]) {
    display: none !important
}

.owl-carousel {

    .owl-stage-outer {
        z-index: 1;

    }

    .owl-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .owl-prev,
    .owl-next {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        transition: 0.4s ease;
        &:focus {
            outline: none;
        }

        &[class*="-next"] {
            right: 0;
            left: auto;
        }
    }

    .owl-dots {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        bottom: 0;
        opacity: 1;

        .owl-dot {
            transition: 0.4s ease;
            &:focus {
                outline: none;
            }
            span {
                transition: 0.4s ease;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.panel-image-overlay {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.panel-image-overlay.gradient.bottom-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* IE10 preview */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}

.panel-image-overlay.gradient.bottom-left {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}


.panel-image-overlay.gradient.top {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-8 */
}

.container-fluid.container-max-width {
    max-width: map-get($container-max-widths, xl);
}

.sticky {
    position: relative;
    > div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}

#pageOverlay, .modal-backdrop {
    background: $backdrop-color;
    position: fixed;
    z-index: 1048;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
}

.navbar-expand-xl {
    @include media-breakpoint-down(lg) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-lg {
    @include media-breakpoint-down(md) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-md {
    @include media-breakpoint-down(sm) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-sm {
    @include media-breakpoint-down(xs) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.modal {
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;

    .modal-dialog {
        transition: 0.4s ease;
        opacity: 0;
        transform: translate3d(0, -2.5rem, 0) !important;
    }
}
.mobile-nav-show #pageOverlay,
.modal.show {
    opacity: 1;
    visibility: visible;

    .modal-dialog {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
    }
    + .modal-backdrop {
        opacity: 1;
        visibility: visible;
    }
}


html {
    .panel-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        box-sizing: content-box;
        ~ * {
            position: relative;
        }
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
    }

    @include media-breakpoint-up(lg) {
        .parallax {
            overflow: hidden;
            position: relative;
        }

        .parallax .panel-image {
            transition: none !important;
            animation: none !important;
        }

        .parallax>.panel-image,
        .parallax .slides li>.panel-image {
            height: 100%;
            transition: 0 !important;
        }

        @media all and (max-width: map-get($grid-breakpoints, md) - 1) {

            // .parallax>.panel-image,
            // .parallax .slides li>.panel-image {
            // }

            .panel-image,
            .slides li>.panel-image {
                top: 0 !important;
                transform: none !important;
                padding: 0;
            }
        }

        &:not([data-browser-type*="InternetExplorer"]),
        &:not([data-browser-type*="Firefox"]),
        &:not([data-user-agent*="Edge"]) {

            .parallax>.panel-image {
                min-height: 100vh;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: -50vh;
            }

            .parallax:first-child .slides li>.panel-image,
            .parallax:first-child .panel-image {
                top: 0;
            }

            .main-container>a:first-child+.parallax .panel-image {
                top: 0;
            }
        }

        &[data-user-agent*="Edge"],
        &[data-user-agent*="iPad"],
        &[data-user-agent*="iPhone"],
        &[data-user-agent*="Android"],
        &[data-browser-type*="Firefox"] {
            .panel-image {
                min-height: 1px;
                transform: none !important;
                top: initial !important;
                min-height: 1px !important;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: 0;
            }
        }

        &[data-browser-type*="InternetExplorer"] {
            .modal {
                .modal-dialog-centered {
                    min-height: 100% !important;
                    margin-top: 0;
                    margin-bottom: 0;

                    .modal-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .parallax > .panel-image {
            transform: none !important;
            top: initial !important;
            min-height: 1px !important;
        }
    }
}

.ken-burns {
    animation: kenBurns 14s ease alternate infinite;
}

@keyframes kenBurns {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

// Apointment Request Form


.list-group-item-action {
    overflow: hidden;

    [type="radio"],
    [type="checkbox"] {
        display: none;

        &~label {
            display: block;
            margin: 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: padding 0.2s ease;
        }

        &:checked~label {
            background: map-get($theme-colors, primary);

            @if (lightness(map-get($theme-colors, primary)) > 65) {
                color: map-get($theme-colors, dark);
            }

            @else {
                color: $white;
            }

            padding-left: 1.5rem;
        }
    }
}

[data-page-type="carecredit"] header,
[data-page-type="carecredit"] #quickLinks {
    background-color: map-get($theme-colors, dark) !important;
}

.faq-list {
    .list-group-item {
        background-color: transparent;
    }
}

@import "navbar";

footer {
    @include media-breakpoint-up(xl) {
        &.position-xl-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(lg) {
        &.position-lg-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(md) {
        &.position-md-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(sm) {
        &.position-sm-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
}

@include media-breakpoint-up(xl) {
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(lg) {
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(md) {
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(sm) {
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(xs) {
    .position-xs-absolute {
        position: absolute !important;
    }
    .position-xs-fixed {
        position: fixed !important;
    }
    .position-xs-relative {
        position: relative !important;
    }
}

[data-toggle="collapse"] {
    .if-open {
        display: inline-block;
    }
        .if-closed {
            display: none;
        }
    &.collapsed {
        .if-open {
            display: none;
        }
        .if-closed {
            display: inline-block;
        }
    }
}

[data-scroll-reveal-after] {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.financing-page {
    &, > * {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .quick-contact-lite-layout-3 textarea {
        height: auto !important;
    }
}

/*========================= Media Queries ========================*/

@include media-breakpoint-down(xl) {}

@media (max-width: 1400px) {
    section.ctas .mod-wrap .img {
        background-size: 350px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 350px !important;
    }
    section.ctas .mod-wrap {
        width: 350px !important;
        height: 183px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 183px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 183px !important;
    }
    // section.hero-section .p1 {
    //     background: url('/prositesStyles/StyleLibrary/6707/images/videoBG.jpg');
    //     background-position: 100% 0;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }
    section.hero-section .wrap .top .social {
        font-size: 19px !important;
    }
    section.hero-section .wrap .top {
        font-size: 24px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 40px !important;
    }
}

@include media-breakpoint-down(lg) {
    section.ctas .mod-wrap .img {
        background-size: 275px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 275px !important;
    }
    section.ctas .mod-wrap {
        width: 275px !important;
        height: 144px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 144px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 144px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 10px !important;
    }
    section.ctas .mod .h-mod1 {
        min-width: 217px !important;
    }
    section.ctas .mod .h-mod2 {
        min-width: 154px !important;
    }
    section.hero-section .wrap .top {
        font-size: 19px !important;
    }
    section.hero-section .wrap .top .social {
        font-size: 16px !important;
    }
    section.ctas .mod .h4 {
        font-size: 16px !important;
    }
    section.hero-section .logo {
        width: 550px;
    }
    section.hero-section, section.hero-section .mod, section.hero-section .wrap {
        height: 800px !important;
    }
    nav.navbar .social {
        padding-left: 29px;
    }
}

@include media-breakpoint-down(md) {
    section.ctas .mod-wrap .img {
        background-size: 200px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 200px !important;
    }
    section.ctas .mod-wrap {
        width: 200px !important;
        height: 104px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 104px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 104px !important;
    }
    section.ctas .mod .h-mod1 {
        min-width: 190px !important;
    }
    section.ctas .mod .h-mod2 {
        min-width: 135px !important;
    }
    section.ctas .mod .h4 {
        font-size: 14px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 95px !important;
    }
    section.contact-form .qc-form-wrapper {
        margin: 0 auto !important;
    }
    section.contact-form .wrap {
        margin: 0 auto;
    }
    section.testimonials-section {
        padding: 0 0 100px !important;
    }
    section.testimonials-section .panel-image {
        height: 100vw;
    }
    section.testimonials-section.testimonials .testimonial-wrapper {
        padding: 115px 15px 30px !important;
    }
    footer.foot .mod {
        line-height: 43px;
    }
}

@include media-breakpoint-down(sm) {
    .galleryButtons .btn {
        margin-bottom: 5px;
    }
    section.ctas .mod-wrap .img {
        background-size: 365px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 365px !important;
    }
    section.ctas .mod-wrap {
        width: 365px !important;
        height: 191px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 191px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 191px !important;
    }
    section.hero-section, section.hero-section .mod, section.hero-section .wrap {
        height: 700px !important;
    }
    section.hero-section .logo {
        width: 420px;
    }
    // section.hero-section .td-mod img {
    //     width: 75px;
    // }
    body.interior section[id*=interiorBanner] .h-mod .wrap .logo {
        max-width: 420px !important;
    }
    body.interior section[id*=interiorBanner], body.interior section[id*=interiorBanner] .h-mod {
        height: 500px !important;
    }
    section.ctas .mw {
        max-width: 400px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 30px !important;
    }
    section.associations .hover-fx {
        width: auto;
        margin: 0 auto 20px !important;
    }
    section.associations .d-flex {
        display: block !important;
        max-width: 290px;
        margin: 0 auto;
    }
}

@include media-breakpoint-down(xs) {
    section.hero-section, section.hero-section .mod, section.hero-section .wrap {
        height: 515px !important;
    }
    section.hero-section .logo {
        width: 269px;
    }
    section.contact-form .wrap a, section.contact-form .wrap span {
        font-size: 20px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 15px !important;
    }
}

@media (max-width: 480px) {
    section.ctas .mod-wrap .img {
        background-size: 265px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 265px !important;
    }
    section.ctas .mod-wrap {
        width: 265px !important;
        height: 138px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 138px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 138px !important;
    }
    section.hero-section .td-mod img {
        width: 60px;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 10px !important;
    }
    section.contact-form .wrap a, section.contact-form .wrap span {
        font-size: 4.5vw !important;
    }
    section.contact-form .wrap {
        padding: 20px 25px !important;
    }
    section.contact-form .wrap i {
        font-size: 70vw !important;
    }
}

@media (max-width: 320px) {
    section.ctas .mod-wrap .img {
        background-size: 215px 100% !important;
    }
    section.ctas .mod .d-flex {
        width: 215px !important;
    }
    section.ctas .mod-wrap {
        width: 215px !important;
        height: 112px !important;
    }
    section.ctas .mod-wrap .slider::-webkit-slider-thumb {
        height: 112px !important;
    }
    section.ctas .mod-wrap .slider::-moz-range-thumb {
        height: 112px !important;
    }
    section.contact-form .wrap {
        padding: 20px !important;
    }
    section.contact-form .wrap .addr, section.contact-form .wrap .hrs, section.contact-form .wrap .ph {
        padding-left: 5px !important;
    }
}
