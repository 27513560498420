
body.mobile-nav-show {
    nav.navbar {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
  
      .current {
        color: $primary !important;
      }
  
      @for $i from 1 to 100 {
        li:nth-child(#{$i}) {
          a {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 0, 0);
            transition-delay: ($i * 0.02s);
          }
        }
      }
    }
  }
  
  nav.navbar:not(.desktop-navbar) {
    .sub-arrow {
        border-radius: $border-radius !important;
        border: none !important;
        transition: transform 0.2s ease;
        margin-right: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -.7em .5em 0 0;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: .25rem;
        padding: 0;
        width: 2em;
        height: 1.4em;
        font-size: 1.25rem;
        line-height: 1.2em;
        text-align: center;
    
        &:before {
          content: '\f105' !important;
          font-family: 'Font Awesome 6 Pro';
          font-weight: lighter;
          margin-right: 0 !important;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0) rotate(90deg);

          body.page-type-10 .wysiwyg & {
              display: none !important;
          }
        }
    
        svg {
          margin-right: 0 !important;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
    }

    overflow-y: auto;
    position: fixed;
    z-index: 1055;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    transition: 0.4s ease;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(25%, 0, 0);
    will-change: transform;
  
    > * {
      width: 80vw;
      max-width: 400px;
    }
  
    #navbarSupportedContent {
      display: block !important;
      height: auto !important;
    }
  
    ul {
      width: auto !important;
      margin: 0 !important;
      border: none !important;
      background-color: transparent !important;
  
      li {
        a {
          font-family: $navbar-font-family !important;
          display: block;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          font-size: 16px * 0.9;
          padding-top: $spacer/2 !important;
          padding-bottom: $spacer/2 !important;
          padding-left: $spacer * 1.5 !important;
          color: #ffffff;
          // color: map-get($theme-colors, dark) !important;
          background-color: transparent !important;
          transition: 0.3s ease;
          opacity: 0;
          visibility: hidden;
          transform: translate3d(0, 1rem, 0);
  
          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 5%;
            left: 10%;
            right: 10%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: rgba(map-get($theme-colors, dark), 0.1) !important;
            opacity: 0;
          }
          body[user-agent*="Mobile"] &:hover:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
  
          &:not(.has-submenu) {
            padding-right: $spacer * 1.5 !important;
          }
        }
  
        a.highlighted {
          background-color: transparent !important;
        }
      }
  
      ul {
        margin-left: $spacer !important;
        margin-bottom: 1rem;
  
        li {
          a {
            font-size: $font-size-base * 0.8;
            font-weight: 400;
          }
        }
      }
    }
  }
  