section.contact-form {
    min-height: 860px;
    
    .mw {
        max-width: 1310px;
        margin: 0 auto;
    }
    .wrap {
        background-color: $secondary;
        padding: 20px 40px;
        border-radius: 20px;
        min-height: 636px;
        max-width: 610px;

        h6 {
            font-size: 20px;
            color: #ffffff;
            letter-spacing: 0.03em;
            font-weight: 700;
            transition: 0.3s;
        }
        span {
            font-size: 21px;
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: 32px;
        }
        a {
            font-size: 21px;
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: 32px;
        }
        .addr, .ph, .hrs {
            position: relative;
            padding-left: 95px;
        }
    }
    p {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 32px;
    }
    ::placeholder {
        color: #26282B;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }
    .form-control {
        color: #26282B;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.02em;
        border: none;
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }
    textarea {
        min-height: 142px;
    }
    [id*="btnSend"] {
        max-width: 196px;
        padding: 14px 40px;
    }
    .qc-form-wrapper {
        max-width: 475px;
        margin-left: auto;
    }

    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 50px 0 0 0;
        }
    }
}
