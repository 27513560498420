section.testimonials-section {
    padding: 255px 0;

    &.testimonials {
        blockquote {
            margin: 0 0 2rem;

            p {
                @include media-breakpoint-up(xxl) {
                    & {
                        font-size: 27px;
                        line-height: 39px;
                    }
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 27px;
                    line-height: 39px;
                }
            }

            a {
                color: #fff;

                &:hover {
                    color: #d9d9d9;
                }
            }

            cite {
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.06em !important;

                :nth-child(2) {
                    display: none !important;
                }
                :nth-child(3) {
                    display: none !important;
                }
            }
        }

        .testimonial-wrapper {
            border-right: none !important;

            @media screen and (max-width: 991px) {
                & {
                    margin-bottom: 0px !important;
                    margin-top: 0 !important;
                }
            }
            @include media-breakpoint-up(xxl) {
                & {
                    padding: 40px 0 30px;
                }
            }
            @media screen and (max-width: 1720px) {
                & {
                    padding: 40px 0 30px;
                }
            }
            @include media-breakpoint-down(xl) {
                & {
                    padding: 40px 0 30px;
                }
            }
            @include media-breakpoint-down(sm) {
                & {
                    padding: 40px 0 30px;
                }
            }
        }
    }
}